import React from 'react';
import PropTypes from 'prop-types';

import PlayButton from '../../../../../svg/play-video.svg';

const PortfolioItem = ({ item, index, playReel, indexPlay }) => {
  const count = ++index;

  if (!item) {
    return null;
  }

  return (
    <div
      className="director-portfolio__item-reel"
      onClick={(e) => playReel(e, indexPlay)}
      role="presentation"
      key={item.id}
      id={item.id}
    >
      <div className="director-portfolio__item-reel__background">
        <div
          className="director-portfolio__item-reel__background-owerflow__scroll"
          data-scroll=""
          data-scroll-speed="-1.5"
        >
          <div className="director-portfolio__item-reel__background-owerflow">
            <video
              src={`https://admin.zerotabletop.com${item.video?.url}`}
              playsInline={true}
              webkit-playsinline=""
              loading="lazy" 
              async=""
              autoPlay={true}
              muted={true}
              loop={true}
              className="director-portfolio__item-reel__video"
            ></video>
          </div>
        </div>
        
        <div className="director-portfolio__item-reel__button">
          <span className="home-videos__slider-item__play-pulse">
            <span className="pulse"></span>
            <PlayButton className="pulse-icon" />
          </span>
        </div>
      </div>
      <div className="director-portfolio__item-reel__info">
        <span className="director-portfolio__item-reel__info-count">
          {count <= 9 ? '0' + count + ' /' : count + ' /'}
        </span>
        <h2 className="director-portfolio__item-reel__info-title">
          {item.title}
        </h2>
      </div>
    </div>
  );
};

PortfolioItem.propTypes = {
  playReel: PropTypes.func,
  item: PropTypes.object,
};

export default PortfolioItem;
