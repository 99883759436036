import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';

const ReactMarkdown = require("react-markdown");

const Biography = ({ biographyData, marker }) => {

  const image = getImage(biographyData.photo.localFile);

  return (
    <section
      className="biography-section person"
      id="biography"
      data-scroll-section
    >
      <div className="content-wrapper">
        <div className="biography">
          <div className="biography-image__wrapper">
          <GatsbyImage image={image} className="biography-image" alt={biographyData.title} />  
          </div>
          <div className="biography-info">
            <span className="biography-info__marker">{marker}</span>
            <h2 className="biography-info__title">{biographyData.title}</h2>
            <div className="biography-info__markdown">
             <ReactMarkdown source={biographyData.biography} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Biography.propTypes = {
  biographyData: PropTypes.object,
  marker: PropTypes.string,
};

export default Biography;
