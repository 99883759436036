import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const CrumbsNav = ({ dataCrumbs, title, slug, link, firstTitle }) => {
  return (
    <section className="crumbs-nav__section" data-scroll-section>
      <div className="content-wrapper">
        <ul className="crumbs-nav">
          {dataCrumbs.map((item, index) => {
            return (
              <li className="crumbs-nav__item" key={index}>
                <Link className="crumbs-naw__item-link" to={item.link}>
                  {item.title}
                </Link>
              </li>
            );
          })}
          {link ? ( 
            <li className="crumbs-nav__item">
              <Link className="crumbs-naw__item-link" to={link}>
                {firstTitle}
              </Link>
          </li>
          ) : null}
          {slug ? ( 
            <li className="crumbs-nav__item">
              <Link className="crumbs-naw__item-link" to={slug}>
                {title}
              </Link>
          </li>
          ) : null}
        </ul>
      </div>
    </section>
  );
};

CrumbsNav.propTypes = {
  dataCrumbs: PropTypes.array,
};

export default CrumbsNav;
