import React from 'react';
import PropTypes from 'prop-types';

import ContentCenter from './ContentCenter/ContentCenter';
import BottomLine from './BottomLine/BottomLine';
// import VideoModal from '../VideoModal/VideoModal';

const MainScreenSecond = ({ videoCover, socials, title, description, poster }) => {
  // const [open, setOpen] = React.useState(false);
  // const [play, setPlay] = React.useState(false);

  // function playReel() {
  //   setOpen(true);
  //   setTimeout(() => {
  //     setPlay((play) => true);
  //   }, 3000);
  // }

  // function stopReel() {
  //   setOpen(false);
  //   setPlay(false);
  // }
  return (
    <>
      <section className="main-screen" id="top-section" data-scroll-section>
        <div className="main-screen__background">
          <video
            preload="auto"
            poster={`https://admin.zerotabletop.com${poster?.url}`}
            className="main-screen__background-video"
            src={`https://admin.zerotabletop.com${videoCover?.url}`}
            loading="lazy" 
            async=""
            playsInline={true}
            webkit-playsinline=""
            autoPlay={true}
            muted={true}
            loop={true}
          ></video>
        </div>

        <div className="page-wrapper">
              <ContentCenter
                title={title}
                  description={description}
                  // playReel={playReel}
                // showreel={showreel}
              />

          <BottomLine socials={socials} />
        </div>
      </section>
      {/* <VideoModal
        stopReel={stopReel}
        play={play}
        open={open}
        showreel={showreel}
      /> */}
    </>
  );
};

MainScreenSecond.propTypes = {
  socials: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  showreel: PropTypes.string,
};

export default MainScreenSecond;
