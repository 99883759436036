import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import Seo from '../components/seo';

import {
  MainScreenSecond,
  CrumbsNav,
  DirectorInnerPortfolio,
  Biography,
} from '../components/Pages/DirectorInner';

import { directorInnerStatic } from '../db/directorInnerStatic';

const DirectorInnerPage = ({data}) => {
  
  const beauty = data.strapiFoodDirectors
  const food = data.strapiBeautyDirectors

  return (
    <Layout>
      <Seo 
        title={beauty ? beauty.seo_title : food.seo_title} 
        description={beauty ? beauty.seo_description : food.seo_description}
      /> 

      <MainScreenSecond
        title={beauty ? beauty.main.title : food.main.title}
        description={directorInnerStatic.main_decription}
        // showreel={beauty ? beauty.main.showreel : food.main.showreel}
        videoCover={beauty ? beauty.main.video_cover : food.main.video_cover}
        poster={beauty ? beauty.main.poster : food.main.poster}
      />

      <DirectorInnerPortfolio
        portfolioData={beauty ? beauty.portfolio : food.portfolio}
        tickerData={directorInnerStatic.tickers}
      />
      
      <Biography
        biographyData={beauty ? beauty : food}
        marker={directorInnerStatic.bio_marker}
      />

      <CrumbsNav 
        dataCrumbs={directorInnerStatic.crumbsNav} 
        link={beauty ? '/food-directors/' : "/beauty-directors/"}
        firstTitle={beauty ? 'Food Directors' : 'Beauty Directors'}
        title={beauty ? beauty.main.title : food.main.title}
        slug={beauty ? "/food-directors/" + beauty.slug : "/beauty-directors/" + food.slug}
      />
    </Layout>
  );
};

export default DirectorInnerPage;


export const query = graphql`
  query DirectorInnerPage($id: String!) {
    strapiFoodDirectors(id: { eq: $id }) {
      seo_title
      seo_description
      main {
        showreel
        title
        video_cover {
          url
        }
         poster{
           url
         }
      }
      portfolio {
        link
        title
        video {
          url
        }
      }
      title
      biography
      slug
      photo {
        localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
      }
    }
    
    strapiBeautyDirectors(id: { eq: $id }) {
      seo_title
      seo_description
      main {
        showreel
        title
        video_cover {
          url
        }
        poster{
           url
         }
      }
      portfolio {
        link
        title
        video {
          url
        }
      }
      title
      biography
      slug
      photo {
        localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
      }
    }
  }
`;
