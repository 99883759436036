import React from 'react';
import PropTypes from 'prop-types';

import Ticker from '../../../UiComponents/Ticker/Ticker';
import PortfolioItem from './PortfolioItem/PortfolioItem';
import VideoModal from '../../../UiComponents/VideoModal/VideoModal';

const DirectorInnerPortfolio = ({ tickerData, portfolioData }) => {
  const [open, setOpen] = React.useState(false);
  const [play, setPlay] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(0);

  function playReel(e, indexPlay) {
    e.preventDefault();
    setOpen(true);
    setTimeout(() => {
      setPlay((play) => true);
    }, 1000);
    setSelectedVideo(indexPlay);
  }

  function stopReel() {
    setOpen(false);
    setPlay(false);
  }

  return (
    <>
      <div
        className="director-portfolio__page second-section"
        id="second-section"
        data-scroll-section
      >
        <Ticker tickers={tickerData} />
        <div className="page-wrapper">
          <div className="director-portfolio__items">
            {portfolioData.reduce((result, item, index) => {
              const indexPlay = index;

              if (index % 2) return result;

              const isReverse = Boolean(result.length % 2);

              result.push(
                <section
                  key={index}
                  className="director-portfolio__item-section"
                >
                  <div className={`item-wrapper ${isReverse && 'reverse'}`}>
                    <PortfolioItem
                      item={item}
                      indexPlay={indexPlay}
                      index={index}
                      playReel={playReel}
                    />
                    <PortfolioItem
                      item={portfolioData[index + 1]}
                      indexPlay={indexPlay + 1}
                      index={indexPlay + 1}
                      playReel={playReel}
                    />
                  </div>
                </section>
              );

              return result;
            }, [])}
          </div>
        </div>
      </div>
      <VideoModal
        stopReel={stopReel}
        play={play}
        open={open}
        showreel={portfolioData[selectedVideo].link}
      />
    </>
  );
};

DirectorInnerPortfolio.propTypes = {
  tickerData: PropTypes.array,
  portfolioData: PropTypes.array,
};

export default DirectorInnerPortfolio;
