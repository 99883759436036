import React from 'react';
import PropTypes from 'prop-types';

// import PlayReelXl from '../../../../svg/play-show-xl.svg';
// import PlayReelSm from '../../../../svg/play-real-sm.svg';

const ContentCenter = ({ title, description }) => {

  return (
    <>
      <div className="content-center">
        <div className="content-center__info">
          <span className="main-decription">{description}</span>
          <h1 className="h1">{title}</h1>
        </div>
        {/* <button className="play-showreel" onClick={playReel}>
          <PlayReelXl className="play-showreel__icon-xl" />
          <PlayReelSm className="play-showreel__icon-sm" />
          <span className="play-showreel__title">Play showreel</span>
        </button> */}
      </div>
      
    </>
  );
};

ContentCenter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  playReel: PropTypes.func,
};

export default ContentCenter;
